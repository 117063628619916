<template>
  <p></p>
  <div class="container-fluid">
    <el-table
        :data="rows"
        height="90vh"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
    >
      <el-table-column
          prop="receipt_id"
          :label="$t('hardlimits.paymentconfirmation.receipt_id')"
      >
      <template #default="props">
        <span @click="to_check(props.row.receipt_id)">
            <span class="link">{{props.row.receipt_id}}</span>
          </span>
      </template>
      </el-table-column>
      <el-table-column prop="bill_nameid" :label="$t('hardlimits.paymentconfirmation.bill_nameid')" />
      <el-table-column prop="sum_payout" :label="$t('hardlimits.paymentconfirmation.sum_payout')" />
      <el-table-column prop="cashier_accept" :label="$t('hardlimits.paymentconfirmation.cashier_accept')" />
      <el-table-column prop="check_data_tz" :label="$t('hardlimits.paymentconfirmation.check_data_tz')" />
      <el-table-column :label="$t('app.public.action')">
        <template #default="scope">
          <el-button size="small" type="success" @click="applyReceipt(scope.row)">Apply</el-button>
          <el-button size="small" type="danger" @click="cancelReceipt(scope.row)">Cancel</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import Hardlimits from "@/services/hardlimits.service";
import {computed, inject, onMounted, ref, watch, watchEffect} from "vue";
import {useStore} from "vuex";
import {useRouter} from 'vue-router'
import ReceiptService from "../../../services/receipt.service";

const $t = inject('$t')

const data=ref({'hardlimit': ''})

const router = useRouter()

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const SelBills = inject('SelBills')
const setIsLoading = inject('setIsLoading')

const currentUser = inject('currentUser')

const rows = ref([])

const to_check = inject('to_check')

function getReceipts()
{
  setIsLoading(true)

  rows.value=[]
  Hardlimits.getReceipts(currentUser.value.bill_id).then(
      (response) => {
        rows.value=response.data
        setIsLoading(false)
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
      }
  )
}

function applyReceipt(data)
{
  setIsLoading(true)

  Hardlimits.applyReceipt(data.receipt_id).then(
      (response) => {
        set_result()
        getReceipts()

        setIsLoading(false)
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
      }
  )
}

function cancelReceipt(data)
{
  setIsLoading(true)

  ReceiptService.receiptCancel(data.receipt_id, data.secure_code).then(
      () => {
        set_result()
        getReceipts()
        setIsLoading(false)
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
      }
  )
}

onMounted(() => {
  getReceipts()
})
</script>

<style scoped>
</style>