import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'hardlimits/';

class HardlimitsService {
  getReceipts(current_bill_id) {
    return axios.post(API_URLA + 'getReceipts/', {'current_bill_id': current_bill_id});
  }
  applyReceipt(receipt_id, secure_code) {
    return axios.post(API_URLA + 'applyReceipt/', {'receipt_id': receipt_id})
  }
}

export default new HardlimitsService();
